export const Apiname = {
  authenticateWeb: "authenticateWeb",
  checkoperator: "user/checkOperator/",
  sendotp: "user/signupWeb",
  verifyOTP: "user/verifyOtpWeb",
  loginVerifyOtp: "user/verifyLoginOTPWeb",
  getflow: "user/getFlow",
  awarenessFlow: "user/awarenessFlow",
  subscribe: "user/subscribeWeb",
  ffsubscribe: "user/ffSubscribe",
  suggested: "user/suggested",
  unsubscribe: "user/unsubscribeWeb",
  foryou: "mostViewed/",
  packages: "user/packages/",
  changepackage: "user/change/package",
  utmList: "user/utmList",
  checkUser: "user/CheckUser",
  viewVideo: "viewVideo",
};

import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { fetchApiPost } from "./Utils/FetchApi";
import { Apiname } from "./Constant/Apiname";
import AwarenessScreen from "./Screen/AwarenessScreen";

const LandingRoute = () => {
  const [token, setToken] = useState<string | null>(null);

  useEffect(() => {
    tokenGenerate();
  }, []);

  const tokenGenerate = async () => {
    try {
      let data: any = {
        username: process.env.REACT_APP_UserName,
        password: process.env.REACT_APP_Password,
      };
      const result = await fetchApiPost(null, Apiname.authenticateWeb, data);
      if (result.token) {
        setToken(result.token);
      }
    } catch (error) {
      console.log("jwt token error", error);
    }
  };

  return (
    <>
      <Routes>
        <Route
          path="/awareness"
          element={<AwarenessScreen token={token} isLanding={false} />}
        />
      </Routes>
    </>
  );
};

export default LandingRoute;

import React, { lazy } from "react";
import ReactDOM from "react-dom/client";
import "@fortawesome/fontawesome-svg-core/styles.css";
import "./Stylesheet/index.css";
import { BrowserRouter } from "react-router-dom";
import LandingRoute from "./LandingRoute";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const App = lazy(() => import("./App"));
root.render(
  <BrowserRouter>
    {window.location.pathname.includes("/awareness") ? (
      <LandingRoute />
    ) : (
      <App />
    )}
  </BrowserRouter>
);
serviceWorkerRegistration.register();
